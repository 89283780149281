// app/javascript/controllers/tooltip_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["info"]

    show() {
        this.infoTarget.style.visibility = 'visible';
    }

    hide() {
        this.infoTarget.style.visibility = 'hidden';
    }
}