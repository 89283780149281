import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    console.log('attendees connected')
    document.addEventListener('quantityChanged', this.handleQuantityChange.bind(this));
  }

  disconnect() {
    document.removeEventListener('quantityChanged', this.handleQuantityChange.bind(this));
  }

  generateAttendeeForms(quantity) {
    this.element.innerHTML = ''; // Clear current attendee forms
    for (let i = 1; i <= quantity; i++) {
      this.element.insertAdjacentHTML('beforeend', this.attendeeFormHTML(i));
    }

    this.setupListeners();
  }

  handleQuantityChange(event) {
    const quantity = event.detail.quantity;
    this.generateAttendeeForms(quantity);
  }

  attendeeFormHTML(index) {
    return `
      <div class="p-4">
        <p class="text-left text-black font-bold text-lg">Attendee ${index}</p>
        <div class="w-full border-t border-gray-300 my-4"></div>

        <div class="space-y-4">
        <div class="input-group flex flex-col w-full">
         <div class="flex items-center">
           <label for="first-name-${index}" class="block text-black font-bold w-1/4">First Name</label>
           <input type="text" id="first-name-${index}" data-validation-target="firstName${index}" name="first-name-${index}" class="flex-1 border border-gray-300 p-2" maxlength="25">
         </div>
        </div>
        <div class="input-group flex flex-col w-full">
        <div class="flex items-center">
          <label for="last-name-${index}" class="block text-black font-bold w-1/4">Last Name</label>
          <input type="text" id="last-name-${index}" data-validation-target="lastName${index}" name="last-name-${index}" class="flex-1 border border-gray-300 p-2" maxlength="25">
        </div>
        </div>
        <div class="input-group flex flex-col w-full">
        <div class="flex items-center">
          <label for="email-${index}" class="block text-black font-bold w-1/4">Email</label>
          <input type="text" id="email-${index}" data-validation-target="email${index}" name="email-${index}" class="flex-1 border border-gray-300 p-2" maxlength="50">
        </div>
        </div>
        <div class="flex items-center">
          <label for="phone-${index}" class="block text-black font-bold w-1/4">Phone</label>
          <input type="text" id="phone-${index}" name="phone-${index}" class="flex-1 border border-gray-300 p-2" maxlength="25">
        </div>
      </div>

      </div>`;
  }

  setupListeners() {
    var firstName = document.getElementById('first-name');
    var lastName = document.getElementById('last-name');
    var email = document.getElementById('email');
  
    var firstNameAttendee = document.getElementById('first-name-1');
    var lastNameAttendee = document.getElementById('last-name-1');
    var emailAttendee = document.getElementById('email-1');

    var isAttendeeCheckbox = document.getElementById('is-attendee');
    isAttendeeCheckbox.addEventListener('change', () => {
      if (isAttendeeCheckbox.checked) {
        firstNameAttendee.value = firstName.value;
        lastNameAttendee.value = lastName.value;
        emailAttendee.value = email.value;
      } else {
        firstNameAttendee.value = '';
        lastNameAttendee.value = '';
        emailAttendee.value = '';
      }
    });
  }
}
