/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require("@rails/ujs").start()
require("channels")

// jQuery in global and window scope
const jQuery = require("jquery")
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

require("select2")
require("tablesorter")
require("bootstrap/js/dist/tooltip");
require("bootstrap/js/dist/collapse");
require("bootstrap/js/dist/dropdown");
require("bootstrap/js/dist/modal");
require("bootstrap/js/dist/alert");
require("bootstrap/js/dist/tab");
require("chart.js/dist/chart.min")

// own
require("nested_fields")
require("bar_chart")

// turbo (disabled by default for now)
import { Turbo } from "@hotwired/turbo-rails"
Turbo.session.drive = false

// stimulus
import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"

window.Stimulus = Application.start()
const context = require.context("../controllers", true, /\.js$/)
Stimulus.load(definitionsFromContext(context))
