import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "menu", "button" ]

  connect() {
    this.menuTarget.classList.add("hidden")
  }

  toggleMenu() {
    this.menuTarget.classList.toggle("hidden")
    this.buttonTarget.classList.toggle("menu-open")

    const srElement = this.buttonTarget.querySelector(".sr-only")
    if(this.menuTarget.classList.contains("hidden")) {
      srElement.textContent = "Open menu"
    } else {
      srElement.textContent = "Close menu"
    }
  }
}
