import { Controller } from "@hotwired/stimulus"
import { enter, leave } from "el-transition"

export default class extends Controller {
  static targets = [ "panel", "overlay" ]

  connect() {
    enter(this.panelTarget, "slideover")
  }

  close() {
    leave(this.panelTarget, "slideover").then(() => {
      this.element.remove();
    })
  }
}
