// inspired by https://stevepolito.design/blog/create-a-nested-form-in-rails-from-scratch
class nestedFields {
  constructor() {
    this.links = document.querySelectorAll('.add_fields')
    this.iterateAddLinks()
    this.iterateRemoveLinks();
    this.iterateApplyAllLinks();
    this.bindDateSelectHelper();
  }

  iterateApplyAllLinks() {
    this.applyAllLinks = document.querySelectorAll('.apply_all_nested')
    if (this.applyAllLinks.length === 0) return
    this.applyAllLinks.forEach(link => {
      link.addEventListener('click', e => {
        this.handleApplyAllClicked(link, e)
      })
    })
  }

  iterateRemoveLinks() {
    this.removeLinks = document.querySelectorAll('.remove_fields')
    if (this.removeLinks.length === 0) return
    this.removeLinks.forEach(link => {
      link.addEventListener('click', e => {
        this.handleRemoveClicked(link, e)
      })
    })
  }

  iterateAddLinks() {
    if (this.links.length === 0) return
    this.links.forEach(link => {
      link.addEventListener('click', e => {
        this.handleAddClicked(link, e)
      })
    })
  }

  updateCounts(addLink, copyFields) {
    const title = addLink.dataset.association;
    const titleCounts = addLink.parentElement.querySelectorAll('.nested-title-count')
    if(titleCounts.length > 1) {
      titleCounts.forEach((titleEl, index) => {
        titleEl.innerText = title + " " + (index + 1)
      })
    } else if(titleCounts.length == 1) {
      titleCounts[0].innerText = title
    }

    const applyAllOptions = addLink.parentElement.querySelectorAll(".apply_all_option")
    applyAllOptions.forEach(optionEl => {
      if(titleCounts.length > 1) {
        optionEl.classList.remove('hidden')
      } else {
        optionEl.classList.add('hidden')
      }
    });

    const costFields = document.querySelector("#trainer-cost-fields").querySelectorAll('.trainer-cost-field')
    let costIndex = 1
    costFields.forEach(costField => {
      costField.querySelector("label").innerHTML = "Trainer Cost (Session " + costIndex + ")"
      costIndex += 1
    });
    if(costIndex > 2) {
      document.querySelector("#copy_costs_btn").classList.remove("hidden")
    } else {
      document.querySelector("#copy_costs_btn").classList.add("hidden")
    }

    const appliableFields = addLink.parentElement.querySelectorAll('.appliable-fields')
    appliableFields.forEach((fields, index) => {
      if(index == 0) return
      if(copyFields) {
        this.copyFieldValues(appliableFields[0], fields);
      }
      if(addLink.dataset.appliedAll == "true") {
        fields.classList.add("hidden")
        this.copyFieldValues(appliableFields[0], fields);
      } else {
        fields.classList.remove("hidden")
      }
    });
  }

  copyFieldValues(from_fields, to_fields) {
    const from_number_fields = from_fields.querySelectorAll("input[type=number]")
    to_fields.querySelectorAll("input[type=number]").forEach((field, index) => {
      field.value = from_number_fields[index].value;
    })

    const from_select_fields = from_fields.querySelectorAll("select")
    to_fields.querySelectorAll("select").forEach((field, index) => {
      field.selectedIndex = from_select_fields[index].selectedIndex
    })

    const from_textarea_fields = from_fields.querySelectorAll("textarea")
    to_fields.querySelectorAll("textarea").forEach((field, index) => {
      field.value = from_textarea_fields[index].value
    })

    const from_text_fields = from_fields.querySelectorAll("input[type=text]")
    to_fields.querySelectorAll("input[type=text]").forEach((field, index) => {
      field.value = from_text_fields[index].value;
    })
  }

  handleAddClicked(addLink, e) {
    if (!addLink || !e) return
    e.preventDefault()

    // unique timestamp to ensure the key of the associated array is unique
    const time = new Date().getTime()
    const linkId = addLink.dataset.id
    const regexp = linkId ? new RegExp(linkId, 'g') : null
    const newFields = regexp ? addLink.dataset.fields.replace(regexp, time) : null
    newFields ? addLink.insertAdjacentHTML('beforebegin', newFields) : null

    // handle trainer cost field moving/showing
    const newCost = addLink.previousElementSibling.querySelector(".trainer-cost-field");
    const costFields = document.querySelector("#trainer-cost-fields");
    costFields.appendChild(newCost);
    newCost.classList.remove('hidden');
    const newCostInput = newCost.querySelector("input");
    const newCostLabel = newCost.querySelector("label");
    newCostInput.classList.remove('disabled');
    newCostInput.removeAttribute('disabled');
    newCostInput.value = costFields.querySelector("input").value;
    newCostLabel.classList.remove('disabled');

    this.updateCounts(addLink, false);
    // bind remove events again
    this.iterateRemoveLinks();
    // bind date selection helper
    this.bindDateSelectHelper();
  }

  bindDateSelectHelper() {
    const startDateSelects = document.querySelectorAll('.nested-fields .start_at select')
    startDateSelects.forEach(field => {
      field.addEventListener('change', function(e) {
        const selectIndex = Array.from(e.target.parentNode.children).indexOf(e.target)+1
        if(selectIndex < 4) {
          e.target.closest('.nested-fields').querySelector(".end_at select:nth-child("+selectIndex+")").value = e.target.value
        }

        // alert if selected date is in the past
        const dateSelects = e.target.parentNode.querySelectorAll("select")
        const daySelect = $(dateSelects[0]).val()
        const monthSelect = $(dateSelects[1]).val()
        const yearSelect = $(dateSelects[2]).val()
        const selectedDate = new Date(yearSelect, monthSelect-1, daySelect)
        const now = new Date()
        nowDate = new Date(now.getFullYear(), now.getMonth(), now.getDate())
        if(selectedDate.getTime() < nowDate.getTime()) {
          alert("Warning: this start date is in the past")
        }
      })
    })
  }

  handleRemoveClicked(removeLink, e) {
    if (!removeLink || !e) return
    e.preventDefault()

    const container = removeLink.closest('.nested-fields').parentElement
    const removeFields = removeLink.closest('.nested-fields');
    const costFields = document.querySelectorAll("#trainer-cost-" + removeFields.id);
    costFields.forEach(costField => {
        costField.remove();
    });

    removeFields.remove();
    if(container) {
      this.updateCounts(container.querySelector('.add_fields'), false)
    }
  }

  handleApplyAllClicked(applyOption, e) {
    if (!applyOption || !e) return

    const container = applyOption.closest('.nested-fields').parentElement
    const addLink = container.querySelectorAll('.add_fields')[0]
    addLink.dataset.appliedAll = (applyOption.checked ? "true" : "false")

    this.updateCounts(addLink, true)
  }
}

window.addEventListener('load', () => { window.nestedFields = new nestedFields() });
