import { Controller } from "@hotwired/stimulus"
import { leave, toggle } from "el-transition"

export default class extends Controller {
  static targets = [ "menu" ]

  connect() {
    leave(this.menuTarget)
  }

  toggleMenu() {
    toggle(this.menuTarget, "dropdown")
  }
}
