// app/javascript/controllers/register_button_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    console.log("RegisterButtonController connected");
  }

  logClick() {
    console.log("Register Now button was clicked");
  }
}
