import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "toggleable" ]

  toggle(event) {
    this.toggleableTargets.forEach(target => {
      const hidden = target.classList.toggle("hidden")
      target.querySelectorAll("input, select, textarea").forEach(field => { field.disabled = hidden })
    })
    event.preventDefault();
  }
}
