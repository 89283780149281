class BarChart {
  static init() {
    document.querySelectorAll('[data-chart="bar"]').forEach(
      function(chartCanvas) {
        BarChart.apply(chartCanvas);
      }
    );
  }

  static apply(chartCanvas) {
    chartCanvas.dataset.titleColor = "#000";
    chartCanvas.dataset.titleWeight = 200;

    let xLabel = chartCanvas.dataset.xlabel;
    let yLabel = chartCanvas.dataset.ylabel;
    chartCanvas.dataset.ylabel = xLabel;
    chartCanvas.dataset.xlabel = yLabel;
    chartCanvas.dataset.titleColor = "#19a4de";
    chartCanvas.dataset.titleWeight = 800;

    new Chart(chartCanvas, {
      type: chartCanvas.dataset.chart,
      data: {
        labels: chartCanvas.dataset.labels.split(","),
        datasets: JSON.parse(chartCanvas.dataset.values)
      },
      options: {
        indexAxis: chartCanvas.dataset.orientation,
        animation: false,
        plugins: {
          legend: {
            display: (chartCanvas.dataset.legend === "true")
          }
        },
        scales: {
          x: {
            grid: {
              display: true
            },
            title: {
              display: true,
              color: chartCanvas.dataset.titleColor,
              font: { size: 14, weight: chartCanvas.dataset.titleWeight },
              text: chartCanvas.dataset.xlabel
            },
            ticks: {
              color: "#000",
              font: { size: 12 },
              autoSkip: false
            }
          },
          y: {
            grid: {
              display: false
            },
            title: {
              display: true,
              color: chartCanvas.dataset.titleColor,
              font: { size: 14, weight: chartCanvas.dataset.titleWeight },
              text: chartCanvas.dataset.ylabel
            },
            ticks: {
              color: "#000",
              font: { size: 12 }
            }
          }
        }
      }
    });
  }
}

window.addEventListener('load', () => { BarChart.init() });
