import { Controller } from "@hotwired/stimulus"
const EasyMDE = require("easymde");

export default class extends Controller {
  static values = { macrosUrl: String, toolbar: String, autoRefresh: Boolean, applyLater: Boolean }

  connect() {
    if(this.applyLaterValue) {
      this.element.controller = this
      this.element.applyMDE = this.applyMDE
    } else {
      this.#apply()
    }
  }

  applyMDE() {
    this.controller.#apply()
  }

  // private
  #apply() {
    if(this.element.easymde) {
      return
    }

    var toolbar = this.#toolBar
    if(this.hasMacrosUrlValue) {
      toolbar.push(this.#macroLink)
    }

    var options = {
      element: this.element,
      toolbar: toolbar,
      spellChecker: false,
      status: false,
      promptURLs: true,
    }

    if(this.autoRefreshValue) {
      options.autoRefresh = { delay: 300 }
    }

    this.element.easymde = new EasyMDE(options)
  }

  get #toolBar() {
    if(this.hasToolbarValue) {
      return this.toolbarValue.split(",");
    } else {
      return [ "link",
        "bold",
        "italic",
        "|",
        "unordered-list",
        "ordered-list",
        "|",
      ]
    }
  }

  get #macroLink() {
    return {
      name: "macro-help",
      action: function openMacros(editor) {
        window.open(editor.element.dataset.markdownEditorMacrosUrlValue, "macros", "height=800,width=680");
      },
      className: "fa fa-star",
      title: "Available Macros"
    }
  }
}
