import { Controller } from "@hotwired/stimulus"
import { copyText } from "helpers"

export default class extends Controller {
  static targets = [ "copyable" ]
  static classes = [ "success" ]

  // Actions

  async copy() {
    this.reset()
    const success = await copyText(this.text)
    if (success) this.element.classList.add(this.successClass)
  }

  reset() {
    this.element.classList.remove(this.successClass)
    this.forceReflow()
  }

  // Private

  get text() {
    const { value, textContent } = this.copyableTarget
    return value || textContent
  }

  forceReflow() {
    this.element.offsetWidth
  }
}
