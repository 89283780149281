import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  scrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }

  confirm(event) {
    const { message } = event.target.closest("[data-message]").dataset
    const result = window.confirm(message)
    if (!result) event.preventDefault()
  }
}
